// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-the-firm-jsx": () => import("./../../../src/pages/about-the-firm.jsx" /* webpackChunkName: "component---src-pages-about-the-firm-jsx" */),
  "component---src-pages-cases-jsx": () => import("./../../../src/pages/cases.jsx" /* webpackChunkName: "component---src-pages-cases-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-recent-results-jsx": () => import("./../../../src/pages/recent-results.jsx" /* webpackChunkName: "component---src-pages-recent-results-jsx" */)
}

